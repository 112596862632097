import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import InvitationForm from "./Pages/InvitationForm";
import Receipt from "./Components/Receipt";
import LandingPage from "./Pages/LandingPage";
import AdminLogin from "./Pages/AdminPage";
import AdminDashboard from "./Pages/AdminDashboard";
import QrScannerPage from "./Pages/QrScannerPage";


const App = () => {
    return (
        <div className="bg-gray-700 h-screen">
          <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/create-invitation" element={<InvitationForm />} />
                <Route path="/receipt/:id" element={<Receipt />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/admin-dashboard" element={<AdminDashboard/>} />
                <Route path="/scan-qr" element={<QrScannerPage />} />
            </Routes>
        </Router>
        </div>
    );
};

export default App;
