import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas"; // QR code generation library


const Receipt = () => {
    const { id } = useParams(); // Fetch the invitation ID from the URL
    const [userData, setUserData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [isDownloadPending, setIsDownloadPending] = useState(false);
    

    useEffect(() => {
        // Fetch the receipt details from the backend using the invitation ID
        const fetchData = async () => {
            try {
                const response = await fetch(`https://teemah.com.ng/api/get-invitation/${id}/`);
                if (!response.ok) {
                    console.error("Failed to fetch data. Status Code:", response.status);
                    throw new Error("Failed to fetch receipt data");
                }

            // try {
            //     const response = await fetch(`http://127.0.0.1:8000/api/get-invitation/${id}/`);
            //     if (!response.ok) {
            //         console.error("Failed to fetch data. Status Code:", response.status);
            //         throw new Error("Failed to fetch receipt data");
            //     }
                const data = await response.json();
                console.log("Fetched data:", data);
                setUserData(data);
    
                // Show pop-up if category matches
                if (["Alumni", "Previous Parents"].includes(data.Category)) {
                    setShowPopup(true);
                }
                // Show pop-up for Prospective Parent
                else if (["Prospective Parents"].includes(data.Category)) {
                    setShowPopup2(true);
                }
            } catch (error) {
                console.error("Error fetching receipt data:", error);
            }
        };
        fetchData();
    }, [id]);
    

  

    const downloadReceiptAsImage = () => {
        if (userData?.Category === "Alumni" || userData?.Category === "Previous Parents") {
            setShowPopup(true); // Show the pop-up
            setIsDownloadPending(true); // Indicate download is pending
            return;
        } else if (userData?.Category === "Prospective Parents") {
            setShowPopup2(true); // Show the pop-up
            setIsDownloadPending(true); // Indicate download is pending
            return;
        }
    
        initiateDownload(); // Proceed to download if no conditions match
    };
    

  const initiateDownload = () => {
      const element = document.getElementById("receipt-container");
      const downloadButton = document.getElementById("download-button");

      if (!element || !downloadButton) {
          console.error("Receipt container or download button not found!");
          return;
      }

      // Hide the download button before rendering
      downloadButton.style.display = "none";

      // Ensure content is fully rendered before capturing the image
      setTimeout(() => {
          html2canvas(element, {
              scale: 3,
              logging: true,
              useCORS: true,
              backgroundColor: "#FFFFFF",
              width: element.offsetWidth,
              height: element.offsetHeight,
              scrollX: 0,
              scrollY: -window.scrollY,
          })
          .then((canvas) => {
            const image = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = image;
        
            // Set the filename as the user's name
            const filename = `${userData.Name}_event_pass.png`; 
            link.download = filename;
        
            link.click();
        })
              .catch((error) => {
                  console.error("Error capturing the receipt image:", error);
              })
              .finally(() => {
                  downloadButton.style.display = "block"; // Show the button again
              });
      }, 200);
  };

  const closePopup = () => {
      setShowPopup(false);
      if (isDownloadPending) {
          setIsDownloadPending(false); // Reset the pending state
          initiateDownload(); // Proceed with the download
      }
  };

  const closePopup2 = () => {
    setShowPopup2(false);
    if (isDownloadPending) {
        setIsDownloadPending(false); // Reset the pending state
        initiateDownload(); // Proceed with the download
    }
};
  
    
    
    

    // Check if the userData has loaded
    if (!userData) {
        return <p>Loading...</p>;
    }

    

    // Rendering the receipt details safely
    return (
        <div  className="w-full bg-gray-800 sm:w-full md:w-full lg:w-full px-4 py-4 min-h-screen bg-yellow-100  flex items-center justify-center">
{/* Pop-up Modal */}
           {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-2xl shadow-2xl text-center w-11/12 md:w-1/4 border border-orange-500">
                    {/* Header */}
                    <h2 className="text-2xl font-extrabold mb-4 text-yellow-500 tracking-wider">
                        Thank You!
                    </h2>
                    
                    {/* Content */}
                    <p className="text-gray-700 leading-relaxed">
                        Thank you, <strong>{userData.Name} </strong>for your continuous support. We would greatly appreciate any donation to this account:
                    </p>
                    <div className="bg-yellow-100 p-4 rounded-lg mt-4">
                        <p className="font-semibold text-gray-800">
                            <span className="text-purple-700 font-bold">Account Number:</span> 2031842486
                        </p>
                        <p className="font-semibold text-gray-800 mt-2">
                            <span className="text-purple-700 font-bold">Bank:</span> First Bank
                        </p>
                        <p className="font-semibold text-gray-800 mt-2">
                            <span className="text-purple-700 font-bold">Account Name:</span> Marzwell Schools Co Curricular
                        </p>
                    </div>
            
                    {/* Button */}
                    <button
                        onClick={closePopup}
                        className="mt-6 bg-yellow-500 text-black px-6 py-3 rounded-full shadow-lg hover:bg-yellow-800 hover:scale-105 transition-transform duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>
            
            )}

            {/* Pop-up Modal2 */}
           {showPopup2 && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-2xl shadow-2xl text-center w-11/12 md:w-1/4 border border-orange-500">
                    {/* Header */}
                    <h2 className="text-2xl font-extrabold mb-4 text-yellow-500 tracking-wider">
                        Welcome! Marzwell Schools Prospective Parents
                    </h2>
                    
                    {/* Content */}
                    <p className="text-gray-700 leading-relaxed">
                Dear <strong>{userData.Name}</strong>, thank you for considering Marzwell Schools for your child's education. 
                We are excited to have you join our family of excellence.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
                Please, kindly reach out to +2348023276543 and +2348023286539 to register your wards.
            </p>
            
                    {/* Button */}
                    <button
                        onClick={closePopup2}
                        className="mt-6 bg-yellow-500 text-black px-6 py-3 rounded-full shadow-lg hover:bg-yellow-800 hover:scale-105 transition-transform duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>
            
            )}

<div           


  id="receipt-container"
  className="  md:mx-auto sm:w-2/3 md:w-3/5 lg:w-1/4 xl:w-3/8 bg-black border-4 border-yellow-500 p-6 rounded-lg shadow-lg my-8"
>
    <div  className="text-center">
        {/* Header Section */}
        <p className="text-sm sm:text-base md:text-xl  uppercase tracking-widest font-bold font-limelight text-yellow-400 ">Marzwell SCHOOLS' 20th Anniversary Dinner</p>
        <p className="font-bold font-sans mt-2 text-white outline-4 outline-black text-2xl sm:text-xl xs:text-xl">{userData.Name || "N/A"}'s Access Card</p>
        
        {/* Date and Venue Section */}
        <div className="bg-yellow-400 flex flex-col rounded-lg w-full text-black shadow-lg px-4 py-4 mt-4">
    {/* Date Section */}
    <div className="flex  items-center">
        <p className="text-sm font-bold">Date:</p>
        <p
            style={{
                fontFamily: 'Georgia, serif',
                fontStyle: 'italic',
                fontSize: '12px',
            }}
            className="font-bold"
        >
            Sunday, December 15, 2024.
        </p>
    </div>

    {/* Time Section */}
    <div className="flex  items-center my-2">
        <p className="font-bold text-sm">Time:</p>
        <p
            style={{
                fontFamily: 'Georgia, serif',
                fontStyle: 'italic',
                fontSize: '12px',
            }}
            className="font-bold"
        >
            Red Carpet- 4PM | Event- 5:30 PM
        </p>
    </div>

    {/* Venue Section */}
    <div className="flex  items-center">
        <p className="text-sm font-bold">Venue:</p>
        <p
            style={{
                fontFamily: 'Georgia, serif',
                fontStyle: 'italic',
                fontSize: '12px',
            }}
            className="font-bold"
        >
            Marzwell Schools' Hall
        </p>
    </div>
</div>



{/* <div className="bg-yellow-300 text-black rounded-lg shadow-lg px-4 py-2 text-center mt-4">
    <p className="text-sm flex"><p className="font-bold">Address: </p> 5, Alexandra Street, Off Oko-Oba Road, Lagos.</p>
  </div> */}

  <hr className="mt-8 text-yellow-500" />




        {/* User Details Section */}
        <div className="bg-yellow-100 p-4 rounded-tr-3xl rounded-bl-3xl mt-4">
  <p className="mt-2 text-left font-bold text-black">Seat Reservation Information</p>
  <div className="flex xs:space-x-2 mt-2">
    {/* Left Side: User Data */}
    <div
      style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic', fontSize: '12px' }}
      className="space-y-2 w-1/2  text-left border-2 border-yellow-500 xs:p-2 p-4 rounded-tr-3xl bg-black text-white overflow-hidden"
    >
      <p className="flex flex-col mt-2">
        <strong>Category:</strong> <span className="text-yellow-500 font-bold break-line">{userData.Category || "N/A"}</span>
      </p>
      <p className="flex flex-col mt-2">
        <strong>Table Name:</strong> <span className="text-yellow-500 font-bold">{userData["Table Number"] || "N/A"}</span>
      </p>
      <p className="flex flex-col mt-2">
        <strong>Seat Number:</strong> <span className="text-xl text-yellow-500 font-bold">{userData["Seat Number"] || "N/A"}</span>
      </p>
      {/* <p>
        <strong>Seats Capacity:</strong> 10
      </p>
      <p>
        <strong>Invitation Id:</strong> {userData["Category"] || "N/A"}/{userData["Invitation Number"] || "N/A"}
      </p> */}
    </div>

    {/* QR Code Section */}
    <div className="flex-none w-1/2 flex flex-col justify-center items-center">
  <QRCodeCanvas
    className="border-white xs:p-1 sm:p-2 p-0 md:p-0" 
    value={JSON.stringify(userData)} 
    size={window.innerWidth < 391 ? 100 : 120} // 100px for small screens, 150px for larger screens
  />
  <p style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic' }} className="text-sm text-center">
    Scan to reveal details.
  </p>
</div>

  </div>
</div>
 

<hr className="mt-4 text-yellow-500" />

        
        
<p className="text-sm mt-2 text-white">
    RSVP to 
    <span className="text-white">
        <em><span className="no-underline text-sm">+2348023276543 (Mrs. Shekoni) and +2348030663644 (Mr. Bamsa)</span></em>
    </span>
</p>

        
        
    </div>

    {/* Download Button */}
    <button id="download-button"

        onClick={downloadReceiptAsImage}
        className="mt-2 bg-yellow-400 text-black font-bold p-3 rounded-lg w-full hover:bg-yellow-500"
        style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic' }}
    >
        Download Invitation Card
    </button>
    <div className="bg-gray-800 text-white py-4 mt-8 text-center ">
  <p style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic', fontSize: '12px' }}className="text-sm xs:text-xs sm:text-xs">
    <span>@TeemahLwittySwiffyFwarry All rights reserved.</span>
  </p>
  <p style={{ fontStyle: 'italic', fontSize: '10px' }}className="mt-2">
  <span className="no-underline">Contact: +2348029958684 to create a reservation site.</span>
</p>

</div>
{/* Watermark Image */}
<img
          src="/logo_mz.png" // Replace with your watermark image path
          alt="Watermark"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-10 w-3/4 sm:w-3/3 md:w-1/4 lg:w-1/6"
        />

</div>

</div>

    );
};

export default Receipt;
