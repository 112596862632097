import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  // Function to handle email submission
  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }

    try {
      // Replace with your backend API endpoint
      const response = await fetch("https://teemah.com.ng/api/get-receipt-by-email/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      // const response = await fetch("http://127.0.0.1:8000/api/get-receipt-by-email/", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ email }),
      // });

      if (!response.ok) {
        throw new Error("Failed to fetch receipt details.");
      }

      const data = await response.json();
      const invitationId = data.id; // Adjust based on the API response structure
      setIsModalOpen(false);
      navigate(`/receipt/${invitationId}`);
    } catch (err) {
      setError("Failed to fetch receipt. Please check the email and try again.");
      console.error(err);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col md:flex-row relative">
  {/* Left container */}
  <div className="relative w-full md:w-1/2 h-80 md:h-screen flex items-center justify-center">
    <div
      className="relative w-3/4 sm:w-60 md:w-96 h-3/4 sm:h-60 md:h-96 bg-yellow-500"
      style={{
        clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
      }}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black text-center">
        {/* Image at the top (hidden on small screens) */}
        <img
          src="/logo_mz.png"
          alt="Top Decoration"
          className="h-10 w-auto md:h-20 md:block hidden mx-auto"
        />

        <p className="text-3xl font-limelight my-6">20th Anniversary</p>

        {/* Image at the bottom (hidden on small screens) */}
        <img
          src="/event_logo1.png"
          alt="Bottom Decoration"
          className="h-10 w-auto md:h-20 md:block hidden mx-auto"
        />

        {/* Watermark Image (visible only on small screens) */}
        <img
          src="/logo_mz.png"
          alt="Watermark"
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 opacity-30  block md:hidden"
        />
      </div>
    </div>
  </div>

  {/* Right side content */}
  <div className="w-full md:w-1/2 font-sans p-6 md:p-8 text-white text-center md:text-left m-auto">
    <h1 className="text-2xl md:text-4xl font-bold mb-4">You Are Invited!</h1>
    <p className="text-md md:text-xl mb-4">
      Join us for our grand <strong>20th Anniversary Dinner</strong> celebration. A night of joy, memories, and future aspirations awaits!
    </p>
    <h2 className="text-lg md:text-2xl font-semibold mb-4">Steps to Register:</h2>
    <ul className="list-decimal list-inside text-md md:text-lg">
      <li>Visit our registration page by clicking the link below.</li>
      <li>Fill out your details and confirm your attendance.</li>
      <li>Download Your Access Card.</li>
    </ul>
    <div className="mt-6 flex flex-col md:flex-row gap-4">
      <Link
        to="/create-invitation"
        className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300 z-50"
      >
        Register Now
      </Link>

      {/* Open Modal */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
      >
        Download Invitation Card
      </button>
    </div>
  </div>

  {/* Modal */}
  {isModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg sm:mx-2 w-11/12 md:w-1/3">
        <h2 className="text-xl font-bold mb-4 text-center ">Enter Your Email</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(""); // Clear error when typing
          }}
          placeholder="Enter your email"
          className="w-full p-3 border border-gray-300 rounded-lg mb-4"
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="flex justify-between">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-600 transition"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )}

  {/* Hidden Admin Login Link */}
  <div className="absolute bottom-4 right-4 z-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
    <Link
      to="/admin-login"
      className="text-white bg-yellow-500 px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
    >
      Admin Login
    </Link>
  </div>
</div>


  );
};

export default LandingPage;